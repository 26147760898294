import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';

import { Photo } from '@/models';

export class Reportconfiguration {
    public tnc?: string;
    public frontpageguide: string;
    public logo: Photo[];
    public primarycolour: string;
    public secondarycolour: string;
    public backgroundcolour: string;
    public frontpagetextcolour: string;
    public showtenants: boolean;
    public showmeters: boolean;
    public showkeys: boolean;
    public showalarms: boolean;
    public showappliancesandmanuals: boolean;
    public showgeneralnotesonhomepage: boolean;
    public showtenantsonhomepage: boolean;
    public showmetersonhomepage: boolean;
    public showkeysonhomepage: boolean;
    public showalarmsonhomepage: boolean;
    public showappliancesandmanualsonhomepage: boolean;
    public showitemlozenges: boolean;
    public showcompliancesectionsonhomepage: boolean;
    public hideroomsonalarms: boolean;
    /**
     * Create an object from a JSON representation
     * @param data
     * @return Report
     */
    public static parse(data: object) {
        const obj = new Reportconfiguration;
        copyInto(obj, data);
        return obj;
    }

    /**
     * Constructor
     */
    public constructor() {
        this.tnc = '';
        this.frontpageguide = '';
        this.primarycolour = '';
        this.secondarycolour = '';
        this.backgroundcolour = '';
        this.frontpagetextcolour = '';
        this.logo = [];
        this.showtenants = false;
        this.showmeters = false;
        this.showkeys = false;
        this.showalarms = false;
        this.showappliancesandmanuals = false;
        this.showgeneralnotesonhomepage = false;
        this.showtenantsonhomepage = false;
        this.showmetersonhomepage = false;
        this.showkeysonhomepage = false;
        this.showalarmsonhomepage = false;
        this.showappliancesandmanualsonhomepage = false;
        this.showitemlozenges = false;
        this.showcompliancesectionsonhomepage = false;
        this.hideroomsonalarms = false;
    }

}
