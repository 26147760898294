import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';

export class Compliancequestion {

    public slug: string;
    public prompt: string;
    public questionType: string;
    public optionList: string[];
    public link: string;
    public conditional: boolean;
    public linkconditionvalue: string;
    public flag: boolean;
    public flagvalue: string;
    public required: boolean;
    public photo: boolean;
    public notes: boolean;
    public order: number;

    /**
     * Create an object from a JSON representation
     * @param data
     * @return Report
     */
    public static parse(data: object) {
        const obj = new Compliancequestion;

        copyInto(obj, data);

        if (!obj.slug) {
            obj.slug = snakeCase(obj.prompt);
        }

        return obj;
    }

    /**
     * Constructor
     */
    public constructor() {
        this.slug = '';
        this.prompt = '';
        this.questionType = '';
        this.optionList = [];
        this.link = '';
        this.conditional = false;
        this.linkconditionvalue = '';
        this.flag = false;
        this.flagvalue = '';
        this.required = false;
        this.photo = false;
        this.notes = false;
        this.order = 0;
    }

}
