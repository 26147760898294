import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import { copyInto } from '@/utilities';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Depository {

  public authToken: string;
  public clientToken: string;
  public linkedBranches: string[];

  /**
   * Create an object from a JSON representation
   * @param data
   * @return CustomerAccountSettings
   */
  public static parse(data?: Partial<Depository>) {
    const obj = new Depository;
    copyInto(obj, data);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.authToken = '';
    this.clientToken = '';
    this.linkedBranches = [];
  }


}
