<template>
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="`smsmodal-label`"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sms-modal-label">
            {{ title }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">To</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="to" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Message</label>
              <div class="col-sm-10 body-editor">
                <textarea class="form-control" rows="10" v-model.lazy="body"></textarea>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal" @click.prevent="hide()">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary" data-dismiss="modal" @click.prevent="send()"
            :disabled="!canSend()">
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, onMounted, inject, defineProps, defineExpose } from 'vue';
import _get from "lodash/get";
import moment from "moment-timezone";
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import {
  Dictionary,
  Report,
  Booking,
  SMSTemplate,
  SMS,
  SMSLogs,
  Tenant,
  Landlord,
  Bookedby,
} from "@/models";

const props = defineProps<{
  id: string;
  name: string;
  title: string;
  reporttype: string;
  target: string;
  chronorder: string;
  customer: string;
}>();

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

const dictionary = computed((): Dictionary => store.getters['dictionary/current'] as Dictionary);
const tenantsms = computed((): SMS => store.getters['smstemplates/tenantsms'] as SMS);
const booking = computed((): Booking => store.getters['diary/booking'] as Booking);

// Convert actions and mutations
const findSMSTemplates = (query?: {
  reporttype?: string;
  target?: string;
  chronorder?: string[];
  managedtype?: string;
  customer?: string;
}): Promise<SMSTemplate> => {
  return store.dispatch('smstemplates/findSMSTemplates', query);
};

const filterSMSTemplate = (query?: {
  reporttype?: string;
  target?: string;
  chronorder?: string[];
  managedtype?: string;
  customer?: string;
}): Promise<SMSTemplate> => {
  return store.dispatch('smstemplates/filterSMSTemplate', query);
};

const loadAllSMSTemplates = (): Promise<SMSTemplate[]> => {
  return store.dispatch('smstemplates/getSMSTemplates');
};

const setTenantSMS = (sms: SMS): void => {
  store.commit('smstemplates/setTenantSMS', sms);
};

const sendSMS = (sms: SMS): Promise<string> => {
  return store.dispatch('diary/sendSMS', sms);
};

const setClientSMSDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('smstemplates/setClientSMSDeep', payload);
};

const setTenantSMSDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('smstemplates/setTenantSMSDeep', payload);
};

const setBookingDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('diary/setBookingDeep', payload);
};

const getSMSLogs = (bookingid: string): Promise<SMSLogs> => {
  return store.dispatch('diary/getSMSLogs', bookingid);
};

const updateTenantSMSConfirmationSent = (bookingid: string): Promise<any> => {
  return store.dispatch('diary/updateTenantSMSConfirmationSent', bookingid);
};

onMounted(async () => {
  //await loadAllSMSTemplates();
});

const init = () => {
  setTemplate();
  setTargetnumbers();
}


const setTargetnumbers = () => {
  let tonumbers: string[] = [];
  if (booking.value && booking.value.tenants && booking.value.tenants.length) {
    tonumbers = booking.value.tenants
      .filter((t: Tenant) => t.ttmobile)
      .map((t: Tenant) => t.ttmobile);
  }
  to.value = tonumbers.join(",");
};

const setTemplate = async () => {
  setTenantSMSDeep({ path: "body", data: "", });
  setTenantSMSDeep({ path: "bookingid", data: "", });

  // Check if this is a last minute booking
  let lastMinuteTemplate = await filterSMSTemplate({
    reporttype: props.reporttype,
    target: props.target,
    chronorder: [props.chronorder, 'Last Minute'],
    managedtype: booking.value.managedtype,
    customer: props.customer,
  });

  let chronorderfilter = [];
  if (props.chronorder) {
    chronorderfilter.push(props.chronorder);
  }

  if (lastMinuteTemplate?.id) {
    const todaymoment = moment().utc();
    const bookingmoment = moment(booking.value.startdate).utc().startOf("day");
    let daysleft = moment.duration(bookingmoment.diff(todaymoment)).asDays();
    if (daysleft <= lastMinuteTemplate.daysbefore) chronorderfilter.push('Last Minute');
  }

  await findSMSTemplates({
    reporttype: props.reporttype,
    target: props.target,
    chronorder: chronorderfilter,
    managedtype: booking.value.managedtype,
    customer: props.customer,
  }).then((smstemplate: SMSTemplate) => {
    if (smstemplate.id) {
      let sms: SMS = new SMS(smstemplate);
      setTenantSMS(sms);
      setTenantSMSDeep({
        path: "body",
        data: convertToPlain(normalise(smstemplate.body)),
      });
      if (booking.value && booking.value.id) {
        setTenantSMSDeep({
          path: "bookingid",
          data: booking.value.id,
        });
        setTargetnumbers();
      }
    }
  })
    .catch((err: any) => actProperty.displayError(err));
};

const convertToPlain = (html: string) => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

const normalise = (html: string): string => {
  placeholderMap.forEach((bookingpath: string, placeholder: string) => {
    let replacevalue = _get(booking.value, bookingpath, "**");
    if (placeholder === "Type of job external") {
      replacevalue = formatReportType(replacevalue);
    } else if (placeholder === "Booked with name") {
      replacevalue = preparebookedbyname(booking.value.bookedby);
    } else if (placeholder === "Landlord name") {
      replacevalue = preparelandlordname(booking.value.landlords);
    } else if (placeholder === "Tenant name") {
      replacevalue = preparetenantname(booking.value.tenants);
    } else if (placeholder === "Invoice amount" && booking.value.invoices) {
      let total = booking.value.invoices.reduce(
        (sum, invoice: Report) => sum + invoice.totalFees(),
        0
      );
      if(isNaN(total)) total = 0;
      if (booking.value.paymentinadv)
        replacevalue = actProperty.formatCurrency(total);
      else replacevalue = "";
    } else if (placeholder === "Date") {
      replacevalue = moment(replacevalue).utc().format("DD/MM/YYYY");
    } else if (placeholder === "Appointment time") {
      replacevalue = moment(replacevalue).utc().format("hh:mm A");
    }

    html = html.replaceAll(
      `<span class="placeholder">[${placeholder}]</span>`,
      replacevalue
    );
  });
  return html;
}

const placeholderMap: Map<string, string> = new Map<string, string>([
  ["Type of job external", "jobtype"],
  ["Address", "address.displayaddress"],
  ["Booked with name", "bookedbyname"],
  ["Appointment time", "appointmenttime"],
  ["Client", "customer.companyName"],
  ["Branch", "customer.branchName"],
  ["Access Branch", "keypickupbranchname"],
  ["Landlord name", "llname"],
  ["Tenant name", "ttname"],
  ["Date", "startdate"],
  ["Invoice amount", ""],
  ["Start time", "starttime"],
  ["End time", "endtime"],
]);

const formatReportType = (type: string) => {
  return actProperty.formatReportType(type, dictionary.value);
}

// Setters and getters
const sms = computed(() => {
  return tenantsms.value;
});

const to = computed({
  get: () => tenantsms.value.to.join(","),
  set: (data: string) => {
    setTenantSMSDeep({ path: `to`, data: data.split(",") });
  }
});

const body = computed({
  get: () => tenantsms.value.body,
  set: (data: string) => {
    setTenantSMSDeep({ path: `body`, data });
  }
});

const tenantnames = computed(() => {
  let names: string[] = [];
  if (booking.value && booking.value.tenants && booking.value.tenants.length) {
    names = booking.value.tenants
      .filter((t: Tenant) => t.ttmobile && t.ttname)
      .map((t: Tenant) => t.ttname);
  }
  return names.join(",");
});

const preparetenantname = (tenants: Tenant[]) => {
  let value: string[] = [];
  if (tenants && tenants.length) {
    value = tenants
      .filter((t: Tenant) => t.ttmobile && t.ttname)
      .map((t: Tenant) => {
        let name = "";
        let splits = t.ttname.split(" ");
        if (splits.length > 0) name = splits[0];
        return name;
      });
  }
  return value.join(", ");
};

const preparelandlordname = (landlords: Landlord[]) => {
  let value: string[] = [];
  if (landlords && landlords.length) {
    value = landlords
      .filter((l: Landlord) => l.llmobile && l.llname)
      .map((l: Landlord) => {
        let name = "";
        let splits = l.llname.split(" ");
        if (splits.length > 0) name = splits[0];
        return name;
      });
  }
  return value.join(", ");
};

const preparebookedbyname = (bookedby: Bookedby[]) => {
  let value: string[] = [];
  if (bookedby && bookedby.length) {
    value = bookedby
      .filter((b: Bookedby) => b.bbmobile && b.bbname)
      .map((b: Bookedby) => {
        let name = "";
        let splits = b.bbname.split(" ");
        if (splits.length > 0) name = splits[0];
        return name;
      });
  }
  return value.join(", ");
};

const canSend = () => {
  return sms.value.body;
}

const send = async () => {
  if (canSend()) {
    await sendSMS(sms.value)
      .then((response: string) => {
        if (response) {
          toasted.success(`SMS successfully sent`);
        }
      })
      .then(() => {
        updateTenantSMSConfirmationSent(booking.value.id);
        hide();

      })
      .then(() => {
        getSMSLogs(booking.value.id);
        hide();

      });
  }
}
const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};
defineExpose({ init, show, hide });
</script>


<style lang="scss">
.placeholder {
  background-color: yellow;
}

.body-editor .ck-editor__editable_inline {
  min-height: 400px;
}

.subject-editor .ck-editor__editable_inline {
  max-height: 100px;
}
</style>