
import { SelectOption, QuestionOption } from "@/models";

export class QuestionWrapper {
    slug: string = "";
    prompt: string = "";
    type: SelectOption = new SelectOption();
    options: QuestionOption[] = [];
    link: SelectOption = new SelectOption();
    conditional: boolean = false;
    linkconditionvalue: string = "";
    flag: boolean = false;
    flagvalue: string = "";
    required: boolean = false;
    photo: boolean = false;
    notes: boolean = false;
}