<template>
  <div class="smstemplates">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>SMS Templates</h1>
        </div>
        <div class="col-sm-6 text-right">
          <router-link class="btn btn-lg btn-primary" to="/smstemplate/new">
            <i class="fas fa-plus-square"></i>
            New
          </router-link>
        </div>
      </div>
    </header>
    <div
      v-if="!smstemplates.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No sms templates found</h2>
    </div>

    <table v-else class="table table-hover" style="padding-bottom: 60px">
      <thead>
        <th width="25%">Title</th>
        <th width="8%">Default</th>
        <th width="30%">Report Type</th>
        <th width="15%">Target</th>
        <th width="15%">Chron Order</th>
        <th width="7%"><span class="sr-only">Actions</span></th>
      </thead>
      <tbody>
        <tr
          v-for="smstemplate in smstemplates"
          :key="smstemplate.id"
          @click="edit(smstemplate)"
        >
          <td class="align-middle">{{ smstemplate.title }}</td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span v-if="smstemplate.defaulttemplate"
                class="multiselecttag"
                >Default</span
              >
            </span>
          </td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span
                class="multiselecttag"
                v-for="reporttype in smstemplate.reporttypes"
                :key="reporttype"
                >{{ reporttype }}</span
              >
            </span>
          </td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span
                class="multiselecttag"
                v-for="t in smstemplate.target"
                :key="t"
                >{{ t }}</span
              >
            </span>
          </td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span
                class="multiselecttag"
                v-for="c in smstemplate.chronorder"
                :key="c"
                >{{ c }}</span
              >
            </span>
          </td>
          <td class="align-middle text-right">
            <ul class="list-unstyled mb-0">
              <li class="list-item" @click.stop>
                <button
                  class="btn btn-outline-danger"
                  @click="remove(smstemplate)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from "vue-toastification";
import { SMSTemplate } from '@/models';

const store = useStore();
const router = useRouter();
const toasted = useToast();

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests'] as boolean);
const smstemplates = computed((): SMSTemplate[] => store.getters['smstemplates/list']);

const deleteSMSTemplate = (smstemplate: SMSTemplate) => {
  store.dispatch('smstemplates/deleteSMSTemplate', smstemplate);
};

const actProperty: any = inject('actProperty');

onMounted(async () => {
  await store.dispatch('smstemplates/getSMSTemplates');
});

const edit = (smstemplate: SMSTemplate) => {
  if ('id' in smstemplate) {
    router.push({
      name: 'smstemplate',
      params: { id: smstemplate.id }
    });
  } else {
    actProperty.displayError("Error");
  }
};

const remove = async (smstemplate: SMSTemplate) => {
  actProperty
    .confirmPrompt()
    .then(() => deleteSMSTemplate(smstemplate))
    .then(() => toasted.success("SMSTemplate deleted"))
    .catch((err: any) => actProperty.displayError(err));
};

</script>

<style scoped lang="scss">
.multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselecttags {
  display: block;
  font-size: 14px;
}
</style>