import _, { transform } from 'lodash';
import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { SMSTemplatesState } from './types';

import { SMSTemplate, SMS } from '@/models';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<SMSTemplatesState, RootState> = {
  /**
   * Get multiple SMSTemplates
   * 
   * @param {SMSTemplatesState} store 
   * @param filters 
   * @returns Multiple SMSTemplates
   */
  async getSMSTemplates(store: ActionContext<SMSTemplatesState, any>): Promise<SMSTemplate[]> {
    const options = {
    };

    store.commit('app/addRequest', 'getSMSTemplates', { root: true });

    return API.get('RestAPI', '/smstemplates', options)
      .then((response) => response.map((x: Partial<SMSTemplate>) => new SMSTemplate(x)))
      .then((smstemplates: SMSTemplate[]) => {
        store.commit('setSMSTemplates', smstemplates);
        return smstemplates;
      })
      .finally(() => store.commit('app/removeRequest', 'getSMSTemplates', { root: true }));
  },

  async findSMSTemplates(store: ActionContext<SMSTemplatesState, any>, query: { reporttype: string, target: string, chronorder: string[], customer: string, managedtype: string }): Promise<SMSTemplate> {
    let sms: SMS = new SMS;
    let filteredlist: SMSTemplate[] = store.state.list.filter((t: SMSTemplate) => {
      if (query.reporttype && t.reporttypes.indexOf(query.reporttype) < 0) return false;
      if (query.target && t.target.indexOf(query.target) < 0) return false;
      if (query.chronorder && query.chronorder.length > 0) {
        for (let i = 0; i < query.chronorder.length; i++) {
          let crord = query.chronorder[i];
          if (t.chronorder.indexOf(crord) < 0) return false;
        }
      }
      if (query.managedtype && t.managedtype && t.managedtype != query.managedtype) return false;
      if (!t.defaulttemplate && query.customer && t.clients.length && t.clients.indexOf(query.customer) < 0) return false;
      return true;
    });
    if (filteredlist.length) {
      sms = new SMS(filteredlist[0])
      if (query.target === 'Managed Client' || query.target === 'Non-Managed Client')
        store.commit('setClientSMS', sms);
      else if (query.target === 'Tenant')
        store.commit('setTenantSMS', sms);
    }

    let smstemplate: SMSTemplate = new SMSTemplate();
    if (filteredlist.length) {
      filteredlist.sort((s1: SMSTemplate, s2: SMSTemplate) => {
        if (s1.clients.length > 0)
          return 1
        else if (s2.clients.length > 0)
          return -1;
        else
          return 0;
      });
      smstemplate = filteredlist[0];
    }
    return smstemplate;
  },

  async filterSMSTemplate(store: ActionContext<SMSTemplatesState, any>, query: { reporttype: string, target: string, chronorder: string[], customer: string, managedtype: string }): Promise<SMSTemplate> {
    let filteredlist: SMSTemplate[] = store.state.list.filter((t: SMSTemplate) => {
      if (query.reporttype && t.reporttypes.indexOf(query.reporttype) < 0) return false;
      if (query.target && t.target.indexOf(query.target) < 0) return false;
      if (query.chronorder && query.chronorder.length > 0) {
        for (let i = 0; i < query.chronorder.length; i++) {
          let crord = query.chronorder[i];
          if (t.chronorder.indexOf(crord) < 0) return false;
        }
      }
      if (query.managedtype && t.managedtype && t.managedtype != query.managedtype) return false;
      if (!t.defaulttemplate && query.customer && t.clients.length && t.clients.indexOf(query.customer) < 0) return false;
      return true;
    });
    if (filteredlist.length) {
      filteredlist.sort((s1: SMSTemplate, s2: SMSTemplate) => {
        if (s1.clients.length > 0)
          return 1
        else if (s2.clients.length > 0)
          return -1;
        else
          return 0;
      });
      return filteredlist[0];
    }
    return new SMSTemplate;
  },

  /**
   * Get one SMSTemplate
   * 
   * @param {SMSTemplatesState} store 
   * @param id 
   * @returns Individual SMSTemplate
   */
  async getSMSTemplate(store: ActionContext<SMSTemplatesState, any>, id) {
    // New SMSTemplate
    if (id === 'new') {
      const smstemplate = new SMSTemplate();
      return smstemplate;
    }
    // Search by Ref
    store.commit('app/addRequest', 'getSMSTemplate', { root: true });
    return API.get('RestAPI', `/smstemplates/${id}`, null)
      .then((response) => new SMSTemplate(response))
      .then((smstemplate: SMSTemplate) => {
        store.commit('setSMSTemplate', smstemplate);
        return smstemplate;
      })
      .finally(() => store.commit('app/removeRequest', 'getSMSTemplate', { root: true }));
  },

  /**
   * Add a SMSTemplate
   * 
   * @param {SMSTemplatesState} store 
   * @param smstemplate SMSTemplate
   * @returns SMSTemplate
   */
  async addSMSTemplate(store: ActionContext<SMSTemplatesState, any>, smstemplate: SMSTemplate) {
    store.commit('app/addRequest', 'addSMSTemplate', { root: true });
    let jsonBody = smstemplate.toJSON();
    return API.post('RestAPI', `/smstemplates`, { body: jsonBody })
      .then((response) => new SMSTemplate(response))
      .then((smstemplate: SMSTemplate) => {
        store.commit('setSMSTemplates', store.state.list.concat(smstemplate))
        store.commit('setSMSTemplate', smstemplate);
        store.commit('resetUnsavedChanges');
        return smstemplate;
      })
      .finally(() => store.commit('app/removeRequest', 'addSMSTemplate', { root: true }));
  },

  /**
   * Update a SMSTemplate
   * 
   * @param {SMSTemplatesState} store 
   * @param smstemplate SMSTemplate
   * @returns Updated SMSTemplate
   */
  async updateSMSTemplate(store: ActionContext<SMSTemplatesState, any>, smstemplate: SMSTemplate) {
    store.commit('app/addRequest', 'updateSMSTemplate', { root: true });
    return API.put('RestAPI', `/smstemplates/${smstemplate.id}`, { body: smstemplate.toJSON() })
      .then((response) => new SMSTemplate(response))
      .then((smstemplate: SMSTemplate) => {
        store.commit('setSMSTemplate', smstemplate);
        store.commit('resetUnsavedChanges');
        return smstemplate;
      })
      .finally(() => store.commit('app/removeRequest', 'updateSMSTemplate', { root: true }));
  },

  /**
   * Delete a SMSTemplate
   * 
   * @param {SMSTemplatesState} store 
   * @param smstemplate SMSTemplate
   * @returns Deleted SMSTemplate
   */
  async deleteSMSTemplate(store: ActionContext<SMSTemplatesState, any>, smstemplate: SMSTemplate): Promise<void> {
    store.commit('app/addRequest', 'deleteSMSTemplate', { root: true });
    return API.del('RestAPI', `/smstemplates/${smstemplate.id}`, {})
      .then(() => store.commit('setSMSTemplates', _.remove(store.state.list, (c: SMSTemplate) => c.id !== smstemplate.id)))
      .then(() => store.commit('setSMSTemplate', new SMSTemplate()))
      .then(() => store.commit('resetUnsavedChanges'))
      .finally(() => store.commit('app/removeRequest', 'deleteSMSTemplate', { root: true }));
  },

  /**
   * Edit a SMSTemplate
   * 
   * @param {SMSTemplatesState} store 
   * @param smstemplate SMSTemplate
   */
  editSMSTemplate(store: ActionContext<SMSTemplatesState, any>, smstemplate: SMSTemplate) {
    store.commit('setSMSTemplate', smstemplate);
    store.commit('addUnsavedChange');
  },

  /**
   * Sets any deep smstemplate property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setSMSTemplateDeep(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('setSMSTemplateDeep', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Sets any deep client sms property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setClientSMSDeep(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('setClientSMSDeep', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Sets any deep tenant sms property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setTenantSMSDeep(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('setTenantSMSDeep', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Adds a client, and increments the "unsaved changes" count
  */
  addClient(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('addClient', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Removes a client, and increments the "unsaved changes" count
  */
  removeClient(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('removeClient', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Adds a reporttype, and increments the "unsaved changes" count
  */
  addReporttype(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('addReporttype', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Removes a reporttype, and increments the "unsaved changes" count
  */
  removeReporttype(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('removeReporttype', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Sets a reporttypes, and increments the "unsaved changes" count
  */
  setReporttypes(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('setReporttypes', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Adds a target, and increments the "unsaved changes" count
  */
  addTarget(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('addTarget', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Removes a target, and increments the "unsaved changes" count
  */
  removeTarget(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('removeTarget', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Sets a targets, and increments the "unsaved changes" count
  */
  setTargets(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('setTargets', data);
    store.commit('addUnsavedChange');
  },

  /**
    * Adds a chronorder, and increments the "unsaved changes" count
    */
  addChronorder(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('addChronorder', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Removes a chronorder, and increments the "unsaved changes" count
  */
  removeChronorder(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('removeChronorder', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Sets a chronorders, and increments the "unsaved changes" count
  */
  setChronorders(store: ActionContext<SMSTemplatesState, any>, data): void {
    store.commit('setChronorders', data);
    store.commit('addUnsavedChange');
  },

}