<template>
  <div class="customer">
    <div class="row">
      <div class="col-10">
        <h1 v-if="isNewCustomer()">Add Customer</h1>
        <h1 v-else>Edit Customer</h1>
      </div>
      <div class="col-1" style="text-align: end;">
        <i v-if="!hasUnsavedChanges && !isNewCustomer()" class="fas fa-arrow-left fa-2x" @click="previouscustomer()"></i>
      </div>
      <div class="col-1" style="text-align: end;">
        <i v-if="!hasUnsavedChanges && !isNewCustomer()" class="fas fa-arrow-right fa-2x" @click="nextcustomer()"></i>
      </div>
    </div>

    <div class="user-header">
      <form>
        <fieldset>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Region</label>
            <div class="col-sm-4">
              <singleselect-text v-model="customerOffice" :options="officeNameOptions"></singleselect-text>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Company Name</label>
            <div class="col-sm-4">
              <multiselect-text v-model="customer.companyName" :options="companyNameOptions" :taggable="true"
                @tag="addCompanyName" @select="setCompanyName">
              </multiselect-text>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Branch Name</label>
            <div class="col-sm-4">
              <multiselect-text v-model="customer.branchName" :options="branchNameOptions" :taggable="true"
                @tag="addBranchName" @select="setBranchName" :disabled="customer.companyName.length < 1">
              </multiselect-text>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <!-- Tabs -->
    <div class="report-items">
      <ul class="nav nav-tabs mb-3" role="tablist">
        <li class="nav-item">
          <router-link to="address" class="nav-link" active-class="active">
            Address
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="account" class="nav-link" active-class="active">
            QC Rules
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="pricelist" class="nav-link" active-class="active">
            Price List
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="establishments" class="nav-link" active-class="active">
            Developments
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="reportsettings" class="nav-link" active-class="active">
            Report Settings
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="logisticsrule" class="nav-link" active-class="active">
            Logistics Rules
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="depository" class="nav-link" active-class="active">
            Depositary
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!-- Status Bar Actions -->
        <div id="status-bar-actions" style="margin-left: auto">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button class="btn btn-outline-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="cancel()"
                :disabled="hasUnsavedChanges">
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button class="btn btn-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="saveAll()"
                :disabled="!hasUnsavedChanges || isSaving">
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from "vue-toastification";
import { Customer, Office } from "@/models";
import { sortCustomerList } from '@/utilities';

const store = useStore();
const router = useRouter();
const route = useRoute();
const hasUnsavedChanges = computed(() => store.getters['customers/hasUnsavedChanges']);
const setCustomer = (customer: Customer) => store.commit('customers/setCustomer', customer);
const addCustomer = async (customer: Customer) => await store.dispatch('customers/addCustomer', customer);
const updateCustomer = async (customer: Customer) => await store.dispatch('customers/updateCustomer', customer);
const getCompanyNameOptions = async () => await store.dispatch('customers/getCompanyNameOptions');
const editCustomer = async (customer: Customer) => await store.dispatch('customers/editCustomer', customer);
const getCustomer = async (id: string) => await store.dispatch('customers/getCustomer', id);
const offices = computed(() => store.getters['offices/getOffices']);
const toasted = useToast();

const customers = computed(() => store.getters['customers/list']);
const sortedcustomers = computed(() => sortCustomerList(customers.value));

const isSaving = ref(false);
const unsavedChanges = ref(false);
const zerotonineloaded = ref(false);
const customer = ref(new Customer());
const companyNameOptions = ref<string[]>([]);
const branchNameOptions = ref<string[]>([]);
const officeNameOptions = ref<string[]>(offices.value.map((office: Office) => office.name));
const actProperty: any = inject('actProperty');

const getBranchNameOptions = async (companyName?: string) => {
  return await store.dispatch('customers/getBranchNameOptions', companyName);
};
const getOfficesOptions = async () => {
  return await store.dispatch('offices/getOffices');
};

const setUnsavedChanges = (value: boolean) => {
  unsavedChanges.value = value;
};

const saveAll = async () => {
  isSaving.value = true;

  try {
    if (isNewCustomer()) {
      const customerData = await addCustomer(customer.value);
      customer.value = customerData;
      toasted.success(`Saved`);
      router.push(`/customers/${customerData.id}`);
    } else {
      const customerData = await updateCustomer(customer.value);
      customer.value = customerData;
      toasted.success(`Updated`);
    }

    setUnsavedChanges(false);
  } catch (err) {
    isSaving.value = false;
    actProperty.value.displayError(err);
  } finally {
    isSaving.value = false;
  }
};

const cancel = () => {
  // Reload the current route to cancel any changes
  router.go(0);
};

const isNewCustomer = () => {
  return router.currentRoute.value.params.id === 'new';
};

getCompanyNameOptions().then(
  (options: any) => (companyNameOptions.value = options)
);
getBranchNameOptions().then(
  (options: any) => (branchNameOptions.value = options)
);

const init = async () => {
  if(sortedcustomers.value.length === 0) {
    // Load customer with current alphabet
    getCustomersAction({starts_with: customer.value.companyName.charAt(0).toLocaleLowerCase()})
  }
  await getOfficesOptions().then((off: Office[]) => {
    officeNameOptions.value = off.map((office: Office) => office.name);
  });
  if (isNewCustomer()) {
    setCustomer(new Customer());
    return;
  }
  await getCustomer(route.params.id as string)
    .then((cust: Customer) => {
      if (cust === undefined) {
        actProperty.displayError(
          `Could not find customer with ID ${route.params.id as string}`
        );
        router.push({ name: "customerlist" });
      } else {
        customer.value = cust;
        onSelectCompanyName(customer.value.companyName);
      }
    })
    .then(() => {
      setTimeout(() => { store.commit('customers/resetUnsavedChanges'); }, 1000)
    })
    .catch((err: any) => actProperty.displayError(err));
};

onMounted(async () => {
  init();
});

const setCompanyName = (val: string) => {
  customer.value.companyName = val;
  customer.value.branchName = '';
  onSelectCompanyName(val);
  setUnsavedChanges(true);
  editCurrentCustomer();
};

const addCompanyName = (val: string) => {
  companyNameOptions.value.unshift(val);
  setCompanyName(val);
  editCurrentCustomer();
};

const setBranchName = (val: string) => {
  customer.value.branchName = val;
  setUnsavedChanges(true);
  editCurrentCustomer();
};

const onSelectCompanyName = async (companyName: string) => {
  if (companyName) {
    branchNameOptions.value = [];
    branchNameOptions.value = await getBranchNameOptions(companyName);
  }
};

const addBranchName = (val: string) => {
  branchNameOptions.value.unshift(val);
  setBranchName(val);
  editCurrentCustomer();
};
const editCurrentCustomer = () => {
  editCustomer(customer.value);
};

const customerOffice = computed({
  get: () => {
    const office = offices.value.find((office: any) => office.id === customer.value.office);
    return office ? office.name : undefined;
  },
  set: (val: string) => {
    const office: Office | undefined = offices.value.find((office) => office.name === val);
    if (office) {
      customer.value.office = office.id;
      setUnsavedChanges(true);
      editCurrentCustomer();
    }
  }
});

watch(() => router.currentRoute.value.params.id, async (val, oldVal) => {
  if (val != undefined && val !== oldVal) {
    init();
  }
});

const getCustomersAction = async (payload?: { starts_with?: string }) => {
  try {
    await store.dispatch('customers/getCustomers', payload);
  } catch (err: any) {
    actProperty.displayError(err)
  }
};

const previouscustomer = async () => {
  if(customer.value.id) {
    const index = sortedcustomers.value.findIndex((c) => c.id === customer.value.id);
    if (index > 0) {
      router.push({ name: "customer", params: { id: sortedcustomers.value[index - 1].id } });
    }
    else if (index == 0 || index == -1) {
      var alpha = customer.value.companyName.charAt(0).toLocaleLowerCase();
      const links = [
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
        'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
      ];
      const linkindex = links.findIndex((a) => a === alpha);
      if(linkindex > 0 && linkindex <= 25) {
        zerotonineloaded.value = false;
        await getCustomersAction({starts_with: links[linkindex - 1]})
        if(customers.value.length > 0)
          router.push({ name: "customer", params: { id: sortedcustomers.value[sortedcustomers.value.length - 1].id } });
        else
          toasted.warning(`Previous list is empty!`);
      }
      else if(linkindex > 25 && linkindex <= 35 && !zerotonineloaded.value) {
        await  getCustomersAction({starts_with: '0-9'});
        zerotonineloaded.value = true;
        if(customers.value.length > 0)
          router.push({ name: "customer", params: { id: sortedcustomers.value[sortedcustomers.value.length - 1].id } });
        else
          toasted.warning(`Previous list is empty!`);
      }
      else {
        toasted.warning(`You have reached end of line!`);
      }
    }
  }
}

const nextcustomer = async () => {
  if(customer.value.id) {
    const index = sortedcustomers.value.findIndex((c) => c.id === customer.value.id);
    if (index >= 0 && index < sortedcustomers.value.length - 1) {
      router.push({ name: "customer", params: { id: sortedcustomers.value[index+1].id } });
    }
    else if (index == sortedcustomers.value.length - 1) {
      var alpha = customer.value.companyName.charAt(0).toLocaleLowerCase();
      const links = [
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
        'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
      ];
      const linkindex = links.findIndex((a) => a === alpha);
      if(linkindex <= 25) {
        zerotonineloaded.value = false;
        await getCustomersAction({starts_with: links[linkindex + 1]})
        if(customers.value.length > 0)
          router.push({ name: "customer", params: { id: sortedcustomers.value[0].id } });
        else
          toasted.warning(`Next list is empty!`);
      }
      else if(linkindex <= 35 && !zerotonineloaded.value) {
        await getCustomersAction({starts_with: '0-9'});
        zerotonineloaded.value = true;
        if(customers.value.length > 0)
          router.push({ name: "customer", params: { id: sortedcustomers.value[0].id } });
        else
          toasted.warning(`Next list is empty!`);
      }
      else {
        toasted.warning(`You have reached end of line!`);
      }
    }
  }
}


</script>


<style scoped lang="scss">
.nav-item {
  a {
    cursor: pointer;
  }
}

#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {

  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.status-bar-separator {
  color: #f8f9fa;
}

#status-bar-switches {
  margin-left: 2rem;
}

#status-bar-actions {
  width: 22rem;
  text-align: right;

  span {
    margin-right: 0.25rem;
  }
}
</style>async 