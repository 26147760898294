<template>
  <div class="report">
    <div class="row">
      <!-- Report Header -->
      <div class="col-sm-5">
        <h1>
          {{ addressPreviewLine1() }}<br />
          {{ addressPreviewLine2() }}
        </h1>
      </div>
      <div class="col-sm-7">
        <div class="row justify-content-end">
          <div class="col-sm-2">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label text-right">Ref</label>
              <div class="col-sm-9">
                <input class="form-control" disabled readonly v-model="statereport.ref" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label text-right">Type</label>
              <div class="col-sm-9">
                <multiselect-text v-if="isNewReport()" v-model="reportType" :options="reportTypes"
                  :custom-label="formatReportType">
                </multiselect-text>
                <input v-else class="form-control" v-model="reportTitle" />
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label text-right">Date</label>
              <div class="col-sm-9">
                <input type="date" class="form-control" min="1990-01-01" v-model="reportDate" />
              </div>
            </div>
          </div>
          <div class="col-sm-3" v-if="isAdministrator && actProperty.isFactoryreport(statereport.type)
            ">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label text-right">Customer</label>
              <div class="col-sm-9">
                <multiselect-text v-model="reportCompanyName" :value="reportCompanyName" :options="companyNameOptions"
                  :allow-empty="true" :taggable="true" @tag="onTagCompanyName" @select="onSelectCompanyName">
                  <template #clear v-if="reportCompanyName.length">
                    <i @mousedown.prevent.stop="onTagCompanyName('')" class="multiselect__clear fa fa-times"
                      aria-label="Clear Company Name"></i>
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isNewReport()" class="text-right">
          <div class="btn-group" role="group" aria-label="Download Report PDFs">
            <!-- New generate report buttons to get around the pdf timeout issues -->
            <ProgressButton @click="actProperty.copyDownloadButton(statereport, 'report')"
              @dblclick="generateReportPdf(statereport, $event)" class="btn btn-outline-primary"
              :title="formatReportType(statereport.type)" :disabled="isSaving">
              <i class="far fa-file-pdf"></i>
              {{ formatReportType(statereport.type) }}
            </ProgressButton>

            <!-- CO reports have an extra PDF download (Summary) -->
            <ProgressButton v-if="statereport.type == 'checkout'"
              @click="actProperty.copyDownloadButton(statereport, 'summary')"
              @dblclick="generateReportSummaryPdf(statereport, $event)" class="btn btn-outline-primary"
              title="Check-Out Summary" :disabled="isSaving || !cosummaryactive">
              <i class="far fa-file-pdf"></i> Summary
            </ProgressButton>
          </div>
          <div class="btn-group ml-3" role="group" aria-label="Download Report PDFs" v-if="!isDataentry">
            <!-- Invoice PDFs -->
            <ProgressButton @click="actProperty.copyDownloadButton(statereport, 'invoice')"
              @dblclick="downloadInvoice(statereport, $event)" :disabled="!statereport.totalFees() || isSaving"
              class="btn btn-outline-primary" title="Invoice">
              <i class="far fa-file-pdf"></i> Invoice
            </ProgressButton>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="report-items">
      <ul class="nav nav-tabs mb-3" role="tablist">
        <li v-if="isAdministrator" class="nav-item">
          <router-link to="invoice" class="nav-link" active-class="active">
            Invoice
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="details" class="nav-link" active-class="active">
            Details
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="rooms" class="nav-link" active-class="active">
            Rooms
          </router-link>
        </li>
        <li v-if="showSection('Keys')" class="nav-item">
          <router-link to="keys" class="nav-link" active-class="active">
            Keys
          </router-link>
        </li>
        <li v-if="showSection('Meters')" class="nav-item">
          <router-link to="meters" class="nav-link" active-class="active">
            Meters
          </router-link>
        </li>
        <li v-if="showSection('Alarms')" class="nav-item">
          <router-link to="alarms" class="nav-link" active-class="active">
            Alarms
          </router-link>
        </li>
        <li v-if="showSection('Manuals')" class="nav-item">
          <router-link to="manuals" class="nav-link" active-class="active">
            Manuals
          </router-link>
        </li>
        <li v-if="showSection('Comments')" class="nav-item">
          <router-link to="comments" class="nav-link" active-class="active">
            Comments
          </router-link>
        </li>
        <li v-if="showSection('Signatures')" class="nav-item">
          <router-link to="signatures" class="nav-link" active-class="active">
            Signatures
          </router-link>
        </li>

        <li v-if="showSection('Visit Checks')" class="nav-item">
          <router-link to="visitchecks" class="nav-link" active-class="active">
            Visit Checks
          </router-link>
        </li>

        <li v-if="statereport.customer.depository.authToken" class="nav-item">
          <router-link to="depositary" class="nav-link" active-class="active">
            Depositary
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!-- Status Bar Buttons -->
        <div id="status-bar-buttons">
          <div class="btn-group" role="group" aria-label="Navigation">
            <button class="btn btn-outline-light" @click="scrollToTop()" title="Back to top">
              <span><i class="fas fa-arrow-up"></i></span>
            </button>
          </div>
          <div class="btn-group" role="group" aria-label="Extra info" v-if="!isDataentry">
            <button class="btn btn-light" data-toggle="modal" data-target="#report-notes-modal"
              title="Notes">
              <i class="far fa-sticky-note"></i>
            </button>
            <button v-if="!isCustomer" class="btn btn-light" data-toggle="modal" data-target="#report-qc-notes-modal"
              title="QC Notes">
              <i class="far fa-sticky-note"></i> QC
            </button>
            <button class="btn btn-light" data-toggle="modal" data-target="#linked-reports-modal"
              @click="getLinkedReports(statereport.id)" title="Linked Reports">
              <i class="fas fa-link"></i><span> ({{ statereport.linkedReports.length }})</span>
            </button>
          </div>
          <div v-if="route.name === 'report-rooms' ||
            route.name === 'dereport-rooms'
            " class="btn-group" role="group" aria-label="Expand/collapse">
            <button class="btn btn-light" @click="expandAll()" title="Expand All">
              <i class="fas fa-expand"></i>
            </button>
            <button class="btn btn-light" @click="collapseAll()" title="Collapse All">
              <i class="fas fa-compress"></i>
            </button>
          </div>
          <div v-if="route.name === 'report-rooms' ||
            route.name === 'dereport-rooms'
            " class="btn-group" role="group" aria-label="Add Room/Item">
            <button class="btn btn-light" data-toggle="modal" data-target="#report-room-modal" title="Add Room">
              <i class="fas fa-plus"></i> Room
            </button>
            <button class="btn btn-light" data-toggle="modal" data-target="#report-item-modal" title="Add Item">
              <i class="fas fa-plus"></i> Item
            </button>
          </div>
          <div v-if="(route.name === 'report-rooms' ||
              route.name === 'dereport-rooms') &&
            statereport.type === 'checkout'
            " class="btn-group" role="group">
            <button class="btn btn-light" @click="sameAsCheckin()" title="Toggle Same as Check-In">
              SACI
            </button>
          </div>
        </div>

        <!-- Status Bar Switches -->
        <div id="status-bar-switches" v-if="false === isNewReport() && !isDataentry">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="locked" :checked="statereport.locked"
              @change="toggleLock()" />
            <label class="custom-control-label text-light" for="locked">
              <span v-if="statereport.locked"><i class="fas fa-lock"></i></span>
              <span v-else><i class="fas fa-unlock"></i></span>
            </label>
          </div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="appendImages" v-model="statereport.appendImages"
              @change="toggleAppendImages()" />
            <label class="custom-control-label text-light" for="appendImages">
              <span v-if="statereport.appendImages"><i class="fas fa-long-arrow-alt-down"></i>&nbsp;<i
                  class="fa fa-camera"></i></span>
              <span v-else><i class="fas fa-arrows-alt-v"></i>&nbsp;<i class="fa fa-camera"></i></span>
            </label>
          </div>
          <div v-if="statereport.type === 'checkout'" class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="flagged" :checked="statereport.flagged"
              @change="toggleFlag()" />
            <label class="custom-control-label text-light" for="flagged">
              <span><i class="fas fa-flag"></i></span>
            </label>
          </div>
          <div v-if="statereport.type === 'checkout'" class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="flaggedCOSM" :checked="statereport.flaggedCOSM"
              @change="toggleCOSMFlag()" />
            <label class="custom-control-label text-light" for="flaggedCOSM">
              <span><i class="fab fa-stripe-s mr-1"></i></span>
              <span><i class="far fa-flag"></i></span>
            </label>
          </div>
        </div>

        <!-- Status Bar Actions -->
        <div id="status-bar-actions">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button class="btn btn-outline-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="cancel()"
                :disabled="!hasUnsavedChanges">
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button class="btn btn-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="saveAll()"
                :disabled="isSaving || !hasUnsavedChanges">
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Modals -->
    <LinkedReportsModal />
    <ReportConflictModal ref="conflictModal" />
    <ReportNotesModal />
    <ReportMergeModal />
    <ReportQCNotesModal />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from "vue-toastification";
import { emitEvent } from '@/eventBus';
import _ from "lodash";

import LinkedReportsModal from '@/components/modals/LinkedReportsModal.vue';
import ReportConflictModal from '@/components/modals/ReportConflictModal.vue';
import ReportNotesModal from '@/components/modals/ReportNotesModal.vue';
import ReportMergeModal from '@/components/modals/ReportMergeModal.vue';
import ReportQCNotesModal from '@/components/modals/ReportQCNotesModal.vue';
import ProgressButton from '@/components/ProgressButton.vue';

import { Report, Dictionary, CustomerDictionary } from "@/models";

const store = useStore();
const router = useRouter();
const route = useRoute();
const actProperty: any = inject('actProperty');
const toasted = useToast();

const conflictModal = ref(null);

const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const customerdictionary = computed((): CustomerDictionary => store.getters['customerdictionary/current']);
const statereport = computed(() => store.getters['reports/current']);
const hasUnsavedChanges = computed(() => store.getters['reports/hasUnsavedChanges']);
const isDataentry = computed(() => store.getters['auth/isDataentry']);
const isCustomer = computed(() => store.getters['auth/isCustomer']);
const isAdministrator = computed(() => store.getters['auth/isAdministrator']);
const cognitoUser = computed(() => store.getters['auth/cognitoUser']);

const removeRequest = (id: string) => {
  store.commit('app/removeRequest', id);
};

const resetUnsavedChanges = () => {
  store.commit('reports/resetUnsavedChanges');
};

const unsetCurrentReport = () => {
  store.commit('reports/unsetCurrentReport');
};

const setCurrentReportLocked = (locked: boolean) => {
  store.commit('reports/setCurrentReportLocked', locked);
};

const setCurrentReportFlagged = (flagged: boolean) => {
  store.commit('reports/setCurrentReportFlagged', flagged);
};

const setCurrentCOSMReportFlagged = (flaggedCOSM: boolean) => {
  store.commit('reports/setCurrentCOSMReportFlagged', flaggedCOSM);
};

const getReport = (id: string) => store.dispatch('reports/getReport', id);
const getLinkedReports = (id: string) => store.dispatch('reports/getLinkedReports', id);
const setReportDeep = (payload: { path: string; data: any }) => store.dispatch('reports/setReportDeep', payload);
const toggleReportLock = (report: Report) => store.dispatch('reports/toggleReportLock', report);
const toggleReportFlag = (report: Report) => store.dispatch('reports/toggleReportFlag', report);
const toggleCOSMReportFlag = (report: Report) => store.dispatch('reports/toggleCOSMReportFlag', report);
const toggleReportAppendImages = (report: Report) => store.dispatch('reports/toggleReportAppendImages', report);
const setCurrentReportSameAsCheckin = () => store.dispatch('reports/setCurrentReportSameAsCheckin');
const addReport = () => store.dispatch('reports/addReport');
const updateReport = () => store.dispatch('reports/updateReport');
const deleteReport = () => store.dispatch('reports/deleteReport');
const downloadInvoiceAction = (report: Report) => store.dispatch('reports/downloadInvoice', report);
const downloadReportAction = (report: Report) => store.dispatch('reports/downloadReport', report);
const generateReportPdfAction = (report: Report) => store.dispatch('reports/generateReportPdf', report);
const generateReportSummaryPdfAction = (report: Report) => store.dispatch('reports/generateReportSummaryPdf', report);
const getCustomersDictionaryAction = (payload?: { companyname?: string; branchname?: string; reporttype?: string }) => store.dispatch('customerdictionary/getCustomerDictionary', payload);
const getReportactiveCompanyNameOptions = (reportname: string) => store.dispatch('customers/getReportactiveCompanyNameOptions', reportname);

// Converted data properties
const companyNameOptions = ref<string[]>([]);
const isSaving = ref(false);

const reportDate = computed({
  get: () => actProperty.dateToLocalTime(statereport.value.date),
  set: (data: string) => {
    if (data) {
      if (isNewReport()) {
        setReportDeep({
          path: "date",
          data: actProperty.datetimeToUTC(data),
        });
      } else if (confirm("Do you really want to change the Report date?")) {
        setReportDeep({
          path: "date",
          data: actProperty.datetimeToUTC(data),
        });
      } else {
        setReportDeep({ path: "date", data: statereport.value.date });
      }
    }
  }
});

const reportTitle = computed({
  get: () => statereport.value.title === undefined ? defaultReportTitle() : statereport.value.title,
  set: (data: string) => setReportDeep({ path: "title", data })
});

const reportType = computed({
  get: () => statereport.value.type,
  set: (data: string) => setReportDeep({ path: "type", data })
});

const addressLine1 = computed({
  get: () => statereport.value.address.line1,
  set: (data: string) => setReportDeep({ path: "address.line1", data })
});

const addressPostcode = computed({
  get: () => statereport.value.address.postcode,
  set: (data: string) => setReportDeep({ path: "address.postcode", data })
});

const reportCompanyName = computed({
  get: () => statereport.value.companyName,
  set: (data: boolean) => setReportDeep({ path: "companyName", data })
});

const onSelectCompanyName = (companyName: string) => {
  if (companyName) {
    reportCompanyName.value = companyName;
  }
}

const reportTypes = computed({
  get: () => {
    if (isDataentry.value) return ["inventory"];
    else return ["inventory", "soc", "checkin", "checkout", "property visit"];
  },
  set: (data: string[]) => { }
});

const onTagCompanyName = (val: string) => {
  reportCompanyName.value = val;
}

const isNewReport = () => {
  return route.params.id == "new";
}

const defaultReportTitle = (): string => {
  return actProperty.formatReportType(
    statereport.value.type,
    dictionary.value
  );
}

const saveAll = () => {  
  if(isDataentry.value && !statereport.value.propertyDetails.tenancystartdate){
    toasted.error(`Tenancy start date required`);
    return;
  }
  if (isNewReport()) {
    addReport()
      .then((report: Report) => {
        toasted.success(`Saved`);
        if (isDataentry.value) router.push(`/dereports/${report.id}`);
        else router.push(`/reports/${report.id}`);
      })
      .catch((err: any) => actProperty.displayError(err));
  } else {
    // No need to save an empty, or default, Report.title
    if (
      !statereport.value.title ||
      statereport.value.title === defaultReportTitle()
    ) {
      setReportDeep({ path: "title", data: undefined });
    }

    // check if invoice detail is valid
    // Depending on customer setting, workorder number of landlord email
    // are required field
    let error = false;
    if (statereport.value.customer) {
      if (
        statereport.value.customer.workordernumberrequired &&
        !statereport.value.workorderNumber
      ) {
        isSaving.value = false;
        error = true;
        actProperty.displayError(
          "Works/Purchase order number is required"
        );
      }
      if (
        statereport.value.customer.accountsettings.llemailrequired &&
        !statereport.value.client.email
      ) {
        isSaving.value = false;
        error = true;
        actProperty.displayError("Client e-mail is required");
      }
    }
    if (!error) {
      toasted.warning(`Saving`);
      isSaving.value = true;
      updateReport()
        .then((report: Report) => {
          isSaving.value = false;
          toasted.success(`Saved`);
        })
        .catch((err: any) => {
          // Check for 409 Conflict
          if (_.get(err, "response.data.statusCode") === 409) {
            const modal = conflictModal.value as any;
            modal.show();
          } else {
            actProperty.displayError(err);
          }
          isSaving.value = false;
        });
    }
  }
}

const init = async () => {
  if (!route.params.id) return;
  try {
    const report = await getReport(route.params.id as string);
    emitEvent("reportLoadedOnEditReportPage",report);
    if (report === undefined) {
      actProperty.displayError("Could not load Report");
      router.push({ name: "reports" });
    }

    if (route.params.id === "new" && isDataentry.value) {
      setReportDeep({
        path: "user",
        data: cognitoUser.value.username,
      });
      reportType.value = "inventory";
      resetUnsavedChanges();
    }

    if (!isAdministrator.value) {
      const routeName = isDataentry.value ? `dereport-details` : `report-details`;
      router.push({
        name: routeName,
        query: { t: new Date().getTime().toString() },
      });
    }

    if (reportType.value === "inventory" && !reportTitle.value) {
      reportTitle.value = "Inventory Make & Check In";
    }

    if (report && report.companyName) {
      let cname = report.companyName;
      if (report.branchName && report.branchName !== "default") {
        cname = report.branchName;
      }
      getCustomerDictionary(cname, "default", report.type);
    }

    if (isAdministrator.value && actProperty.isFactoryreport(report.type)) {
      try {
        const values = await getReportactiveCompanyNameOptions(report.type);
        companyNameOptions.value = values;
      } catch (err) {
        actProperty.displayError(err);
      }
    }
  } catch (err) {
    actProperty.displayError(err);
    const replaceRoute = isDataentry.value ? "/dereports" : "/reports";
    router.replace(replaceRoute);
  }
};

const getCustomerDictionary = (
  companyname: any,
  branchname: any,
  reporttype: any
) => {
  if (companyname && companyname === "ACT") companyname = "default";
  getCustomersDictionaryAction({
    companyname,
    branchname,
    reporttype,
  }).catch((err: any) => {
    actProperty.displayError(err);
  });
};

const cosummaryactive = computed(() => {
  let result = true;
  if (
    statereport.value &&
    statereport.value.customer &&
    statereport.value.customer.reportsettings
  ) {
    result =
      statereport.value &&
      statereport.value.customer &&
      statereport.value.customer.reportsettings.cosummaryactive;
  }
  return result;
});

const showSection = (section: string) => {
  let flag: boolean = false;
  if (actProperty.isFactoryreport(statereport.value.type)) {
    switch (section) {
      case "Visit Checks":
        flag = true;
        break;
      case "Keys":
        flag = customerdictionary.value.report_configuration.showkeys;
        break;
      case "Meters":
        flag = customerdictionary.value.report_configuration.showmeters;
        break;
      case "Alarms":
        flag = customerdictionary.value.report_configuration.showalarms;
        break;
      case "Manuals":
        flag =
          customerdictionary.value.report_configuration
            .showappliancesandmanuals;
        break;
      case "Comments":
        flag = false;
        break;
    }
  } else {
    switch (statereport.value.type) {
      case "inventory":
        flag = true;
        break;
      case "soc":
        flag = true;
        break;
      case "checkin":
        flag = true;
        break;
      case "checkout":
        flag = true;
        break;
      default:
        throw Error("Unexpected report type");
    }

    if (section == "Visit Checks") {
      flag = false;
    } else if (section === "Depository") {
      flag = false;
      // Only show depository for checkout and if the associated customer is active on depositary
      if (
        statereport.value.type === "checkout" &&
        statereport.value?.customer?.depository?.authToken
      ) {
        flag = true;
      }
    }
  }
  return flag;
}

const scrollToTop = () => {
  window.scrollTo(0, 0);
}

const cancel = () => {
  resetUnsavedChanges();
  router.go(0);
}

/**
 * Toggle lock
 */
const toggleLock = () => {
  toggleReportLock(statereport.value)
    .then((report: Report) => {
      setCurrentReportLocked(report.locked);
      toasted.success(
        `${report.ref} ${report.locked ? "Locked" : "Unlocked"}`
      );
    })
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Toggle Flag
 */
const toggleFlag = () => {
  toggleReportFlag(statereport.value)
    .then((report: Report) => {
      setCurrentReportFlagged(report.flagged);
      toasted.success(
        `${report.ref} ${report.flagged ? "Flagged CO Report" : "Unflagged CO Report"
        }`
      );
    })
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Toggle COSM Flag
 */
const toggleCOSMFlag = () => {
  toggleCOSMReportFlag(statereport.value)
    .then((report: Report) => {
      setCurrentCOSMReportFlagged(report.flaggedCOSM);
      toasted.success(
        `${report.ref} ${report.flaggedCOSM ? "Flagged COSM Report" : "Unflagged COSM Report"
        }`
      );
    })
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Toggle Appended Images
 */
const toggleAppendImages = () => {
  toggleReportAppendImages(statereport.value)
    .then((report: Report) =>
      toasted.success(
        report.appendImages ? "Images appended" : "Images by section"
      )
    )
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Download Invoice PDF
 */
const downloadInvoice = (report: Report, requestId: string): void => {
  downloadInvoiceAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Invoice ${report.ref} PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Generate INV/CI/CO Report PDF
 */
const generateReportPdf = (report: Report, requestId: string): void => {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Generate COSM Report PDF
 */
const generateReportSummaryPdf = (report: Report, requestId: string): void => {
  generateReportSummaryPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} Summary PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

/**
 * Expand All Rooms
 */
const expandAll = () => {
  emitEvent("expandAll", );
}

/**
 * Collapse All Rooms
 */
const collapseAll = () => {
  emitEvent("collapseAll");
}

/**
 * Apply SACI to all CO Condition Notes
 */
const sameAsCheckin = () => {
  actProperty
    .confirmPrompt(
      'Apply "Same as Check-In" to all items without Check-Out conditions?'
    )
    .then(() => setCurrentReportSameAsCheckin())
    .then((amends: string) =>
      toasted.success(`"Same as Check-In" applied to ${amends} items`)
    )
    .catch((err: any) => actProperty.displayError(err));
}

const addressPreviewLine1 = () => {
  return `${addressLine1.value}${addressLine1.value ? "," : ""}`;
}

const addressPreviewLine2 = () => {
  return `${addressPostcode.value}`;
}

const formatReportType = (type: string) => {
  return actProperty.formatReportType(type, dictionary.value);
}

onMounted(() => {
  init();
})

watch(() => route.params.id, (newValue) => {
  if (route.params.id) {
    init();
  }
});

</script>


<style scoped lang="scss">
.tab-content {
  padding-top: 1em;
}

.report-header {
  margin-bottom: 1em;
}

.form-control-plaintext {
  font-weight: bold;
  outline: none;
}

.nav-item {
  a {
    cursor: pointer;
  }
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #4caf50;
  border-color: #4caf50;
}

#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {

  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.status-bar-separator {
  color: #f8f9fa;
}

#status-bar-switches {
  margin-left: 2rem;
}

#status-bar-actions {
  width: 22rem;
  text-align: right;

  span {
    margin-right: 0.25rem;
  }
}

// .btn-back-to-top {
//   border-radius: 1rem;
//   padding: .1rem .35rem;
// }
</style>
