import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, Address, Document, QCRules } from '@/models';
import { toCamelCase } from '@/utilities';

import { CustomerAccountSettings, CustomerReportSettings, Depository } from '@/models';
import { Policies } from './customer/policies.model';
import { EmailSettings } from './customer/emailsettings.model';

export class Customer extends Base {

  public id: string;
  public office: string;
  public companyName: string;
  public companySlug: string;
  public branchName: string;
  public co: string;
  public address: Address;
  public hqbranch: boolean;
  public editable: boolean;
  public acClientCode: string;
  public nominalCode: string;
  public feedbacklinkactive: boolean;
  public feedbacklinkactivedays: number;
  public createdAt: string;
  public updatedAt: string;
  public workordernumberrequired: boolean;
  public midtermactive: boolean;
  public hidefrontpageaddress: boolean;
  public accountsettings: CustomerAccountSettings;
  public reportsettings: CustomerReportSettings;
  public policies: Policies;
  public emailsettings: EmailSettings;
  public pricelist: Document[];
  public establishments: Customer[];
  public depository: Depository;
  public qcrules: QCRules;

  /**
   * Constructor initialises the variables from the class Customer
   * @param data - the data to construct a type with all properties of <Customer>
   */
  public constructor(data?: Partial<Customer>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.office = _get(data, 'office', '');
    this.companyName = _get(data, 'companyName', '');
    this.companySlug = _get(data, 'companySlug', '');
    this.branchName = _get(data, 'branchName', '');
    this.co = _get(data, 'co', '');
    this.address = new Address(_get(data, 'address'));
    this.acClientCode = _get(data, 'acClientCode', '');
    this.hqbranch = _get(data, 'hqbranch', false);
    this.editable = _get(data, 'editable', false);
    this.nominalCode = _get(data, 'nominalCode', '');
    this.feedbacklinkactive = _get(data, 'feedbacklinkactive', false);
    this.feedbacklinkactivedays = _get(data, 'feedbacklinkactivedays', 0);
    this.createdAt = _get(data, 'createdAt', '');
    this.updatedAt = _get(data, 'updatedAt', '');
    this.workordernumberrequired = _get(data, 'workordernumberrequired', false);
    this.midtermactive = _get(data, 'midtermactive', false);
    this.hidefrontpageaddress = _get(data, 'hidefrontpageaddress', false);
    this.accountsettings = CustomerAccountSettings.parse(_get(data, 'accountsettings'));
    this.reportsettings = CustomerReportSettings.parse(_get(data, 'reportsettings'));
    this.policies = Policies.parse(_get(data, 'policies'));
    this.emailsettings = EmailSettings.parse(_get(data, 'emailsettings'));
    this.pricelist = _castArray(_get(data, 'pricelist', [])).map((x: Partial<Document>) => new Document(x));
    this.establishments = _castArray(_get(data, 'establishments', [])).map((x: Partial<Customer>) => new Customer(x));
    this.depository = Depository.parse(_get(data, 'depository'));
    this.qcrules = QCRules.parse(_get(data, 'qcrules', {}));
  }

  public getQCRule(jobtype: string, target: string) {
    return this.qcrules.getQcrule(jobtype, target);
  }

}
